var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancel'
  }},on:{"errorMessageClosed":function($event){_vm.errorMessage = ''},"toolbarSelectItem":_vm.toolbarSelectItem}},[(!_vm.resourceNotFound)?[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],staticClass:"-mt-4"},[(_vm.editing && _vm.editing._id)?_c('div',[_c('h1',{staticClass:"p-0 font-medium text-2xl mb-8"},[_vm._v(_vm._s(_vm.editing.title))]),_c('div',{staticClass:"text-left break-words",domProps:{"innerHTML":_vm._s(_vm.notificationMessage)}}),_c('div',{staticClass:"mt-8"},[(
              _vm.editing.attachmentsData && _vm.editing.attachmentsData.length > 0
            )?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.fetchingAttachmentActivity),expression:"fetchingAttachmentActivity"}],attrs:{"element-loading-text":"Fetching attachments..."}},[_c('div',{staticClass:"mb-8",attrs:{"id":"attachments"}},[_c('p',{staticClass:"text-lg mb-2"},[_vm._v("Attachments:")]),_vm._l((_vm.editing.attachmentsData),function(item,index){return _c('span',{key:index,staticClass:"cursor-pointer inline-block mr-2",attrs:{"target":"_blank"},on:{"click":() => _vm.downloadFile(item)}},[_c('font-awesome-icon',{staticClass:"text-blue-700 hover:text-blue-400 mr-2",attrs:{"icon":['fal', _vm.getIcon(item)]}}),_c('span',{staticClass:"text-blue-700 hover:text-blue-400"},[_vm._v(_vm._s(item.name))])],1)})],2)]):_vm._e(),(_vm.editing.requireAcknowledgement && _vm.hasReadAllAttachments)?_c('div',[(!_vm.notificationAcknowledged && _vm.isRecipient)?_c('div',{staticClass:"bg-gray-100",attrs:{"id":"acknowledgement"}},[_c('div',{staticClass:"flex items-baseline p-6",class:_vm.attachmentsRead ? 'bg-gray-200' : 'bg-red-200'},[_c('el-checkbox',{staticClass:"block mr-4",attrs:{"size":"large"},model:{value:(_vm.attachmentsRead),callback:function ($$v) {_vm.attachmentsRead=$$v},expression:"attachmentsRead"}}),_c('p',[_vm._v(" I acknowledge that the following communication and/or document has been read and distributed to the necessary agency staff. Any questions regarding this communication and/or document, please call Marketing or Underwriting. ")])],1),(_vm.attachmentsRead)?_c('div',{staticClass:"flex justify-between space-x-4 mt-8 px-4 pb-8"},[_c('TextField',{ref:"field_email",staticClass:"flex-grow",attrs:{"label":"Enter your first and last name","placeholder":"","required":true,"value":_vm.name,"fieldGroupClass":"p-0","type":"email","focusOnMount":true,"customValidator":_vm.acknowledgedNameValidator,"validations":[
                    {
                      method: 'custom',
                      error: 'Please enter first name and last name'
                    }
                  ]},on:{"input":function($event){_vm.name = $event}}}),(_vm.acknowledgedNameValidator())?_c('IAButton',{attrs:{"title":"Submit Acknowledgement","click":() => _vm.processAcknowledgement(),"type":"success"}}):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.notificationAcknowledged)?_c('div',[_c('el-tag',{attrs:{"type":"success"}},[_vm._v("NOTIFICATION ACKNOWLEDGED")])],1):_vm._e()]):_vm._e(),(_vm.editing.requireAcknowledgement && !_vm.hasReadAllAttachments)?_c('div',[_c('el-tag',{attrs:{"type":"info"}},[_vm._v(" This communication requires acknowledgement. You'll have to view all attachments to be able to acknowledge this communication. ")])],1):_vm._e()])]):_vm._e()])]:_c('ResourceNotFound',{attrs:{"title":"Resource not found.","subtitle":"Sorry, the notification you were looking for could not be found.","actionButtonTitle":"Back to notifications","actionRedirectPath":_vm.notificationRoute}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }