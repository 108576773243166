
import * as types from "@/store/mutation-types";
import { getFileIcon, getPathAPI } from "@/helpers";
import {
  notificationMapActions,
  notificationMapMutations,
  notificationMapState
} from "@/store/modules/notification";
import { Tag } from "element-ui";
import Vue from "vue";
import { authMapGetters } from "@/store/modules/auth";
import { get, unescape } from "lodash";
import { activitiesMapActions } from "@/store/modules/activities";
export default Vue.extend({
  components: {
    "el-tag": Tag
  },
  name: "read-notification",
  data() {
    return {
      attachmentsRead: false,
      name: "",
      errorMessage: "",
      resourceNotFound: false,
      fetchingAttachmentActivity: false
    };
  },
  methods: {
    ...notificationMapActions([
      "createNotification",
      "acknowledgeNotification",
      "getNotification",
      "getNotifications",
      "deleteNotification"
    ]),
    ...activitiesMapActions(["getActivities"]),
    ...notificationMapMutations({
      editNotificationField: types.SET_EDIT_FIELD
    }),
    async fetchNotification(): Promise<void> {
      if (!this.notificationId || this.notificationId.length < 12) return;
      try {
        const response = await this.getNotification(this.notificationId);

        if (response && Object.keys(response).length) {
          await this.fetchNotificationAttachmentActivity();
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      }
    },
    async fetchNotificationAttachmentActivity() {
      if (
        !this.editing ||
        !Object.keys(this.editing).length ||
        !this.editing.attachments ||
        !this.editing.attachments.length
      ) {
        return;
      }
      try {
        this.fetchingAttachmentActivity = true;
        const response = await this.getActivities({
          query: {
            activityType: "DocumentViewActivity",
            userId: this.$getCurrentUser._id,
            "data.documentId__in": this.editing.attachments.join(","),
            __sort: "-createdOn"
          }
        });
        this.editNotificationField({
          key: "attachmentsReadActivityData",
          value: response
        });
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.fetchingAttachmentActivity = false;
      }
    },
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "cancel":
          this.doRedirect();
          break;
      }
    },
    async processAcknowledgement(): Promise<void> {
      if (this.name.length > 0) {
        if (
          this.name.toLowerCase() == this.getCurrentUser?.fullName.toLowerCase()
        ) {
          try {
            const acknowledgementResponse = await this.acknowledgeNotification({
              id: this.notificationId,
              name: this.name
            });
            const { requireAcknowledgement = false } = acknowledgementResponse;
            this.notificationAcknowledged = requireAcknowledgement;
            this.$appNotifySuccess(
              `${this.editing.title} Notification successfully acknowledged`
            );
            this.doRedirect();
          } catch (error) {
            this.errorMessage =
              error.message || "Error processing acknowledgment";
          }
        } else {
          this.errorMessage = `Invalid name. Expecting ${this.getCurrentUser?.fullName}`;
        }
      } else {
        this.errorMessage = "Please enter name";
      }
    },
    acknowledgedNameValidator(): boolean {
      if (!this.name || this.name.trim().length === 0) return false;
      const components = this.name.split(" ");
      if (components.length < 2) return false;
      const [firstName, lastName] = this.name.split(" ");
      return !!(
        firstName &&
        firstName.trim().length > 0 &&
        lastName &&
        lastName.trim().length > 0
      );
    },
    getIcon(item: any) {
      return getFileIcon(item);
    },
    getDocumentLink(document: any): string {
      const baseUrl = getPathAPI();
      return `${baseUrl}/file/view/${document._id}`;
    },
    downloadFile(item: any) {
      const accessToken = localStorage.getItem("access");
      const baseUrl = getPathAPI();
      const element = document.createElement("a");
      element.href = `${baseUrl}/file/view/${item._id}?accessToken=${accessToken}`;
      element.download = "";
      element.target = "_blank";
      element.click();

      // this.fetchNotification(); --> This wasn't working because we kind of fetch the new notification
      // before the document is downloaded. What we want is a way to know if the document has been downloaded
      // TODO: This is a compromise - what I learnt is that we end up actually hitting the endpoint
      //  after the user clicks on the document and we trigger the element.click() above.
      // that means that the activity is actually created but the above attempt to update the
      // editing notification doesn't seem to come back with the created activity. This is a reasonable
      // compromise - near the truth and is probably the truth.
      this.editNotificationField({
        key: "attachmentsReadActivityData",
        value: [
          ...this.attachmentsReadActivityData,
          { data: { documentId: item._id } }
        ]
      });
    },
    doRedirect() {
      return this.getCurrentUser && this.getCurrentUser.role == "admin"
        ? this.$router.push("/admin/notifications").catch(() => {})
        : this.$router.push("/notifications").catch(() => {});
    }
  },
  computed: {
    ...notificationMapState(["editing", "makingApiRequest"]),
    ...authMapGetters(["getCurrentUser"]),
    notificationAcknowledged(): boolean {
      if (this.editing) {
        return !!(
          this.editing.acknowledgement &&
          this.editing.acknowledgement.acknowledgedBy
        );
      }
      return false;
    },
    isRecipient(): boolean {
      return (
        this.$getCurrentUser &&
        this.editing &&
        this.$getCurrentUser._id === this.editing.userId
      );
    },
    hasReadAllAttachments(): boolean {
      if (!this.editing || this.editing.attachments.length === 0) return true;
      if (
        this.editing.attachments.length >
        this.attachmentsReadActivityData.length
      ) {
        return false;
      }
      const attachmentsSet = new Set(this.editing.attachments);
      for (const docReadActivity of this.attachmentsReadActivityData) {
        if (!docReadActivity.data) continue;
        if (!attachmentsSet.has(docReadActivity.data.documentId)) {
          return false;
        }
      }
      return true;
    },
    attachmentsReadActivityData(): any[] {
      return get(this.editing, "attachmentsReadActivityData", []);
    },
    notificationId(): string {
      return this.$route.params.notification_id;
    },
    notificationRoute(): string {
      return this.$isCurrentUserAdmin
        ? "/admin/notifications"
        : "/notifications";
    },
    notificationMessage(): string {
      const message = this.editing.message || "";
      return unescape(message);
    }
  },
  async mounted() {
    await this.fetchNotification();
  }
});
